/*********** Routes for applications **************/
import React, { lazy } from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import AppRoute from './AppRoute';
import { Auth } from '../auth';
import { publicLayout, privateLayout } from '../components/Layouts';
import { socketConnection } from '../utilities/socket';

const TabsProvider = lazy(() => import(`../components/provider/Porvider`));
const YoutubePlayer = lazy(() => import(`../components/YoutubePlayer`));

const Routers = (store) => {
  socketConnection.on('receive-message', (data) => {
    //console.log("socketConnection aapp", data)
    let element = document.getElementById('ChatNotification');
    if (element) element.click();
    let el2 = document.getElementById('getFriendListJs');
    if (el2) el2.click();
  });

  const routes = [
    { url: "/", path: "login", layout: publicLayout },
    { url: "/signup", path: "login", layout: publicLayout },
    { url: "/signin", path: "login", layout: publicLayout },
    { url: "/blogdetail/:id", path: "blogs/blogdetail", layout: publicLayout },
    { url: "/bloglisting", path: "blogs", layout: publicLayout },
    { url: "/bloglisting/:cat_id", path: "blogs", layout: publicLayout },
    { url: "/memberedit", path: "member/memberEdit", layout: privateLayout },
    { url: "/memberlisting", path: "member/memberListing", layout: privateLayout },
    { url: "/membermedia/:userId/:type", path: "member/memberMediaPage", layout: privateLayout },
    { url: "/journeyList", path: "project/projectListing", layout: privateLayout },
    { url: "/myjourney", path: "project/MyJorney", layout: privateLayout },
    { url: "/myjourney/:id", path: "project/JourneyEdit", layout: privateLayout },
    { url: "/profile", path: "profileUpdate", layout: privateLayout },
    { url: "/showarchives", path: "youtube", layout: privateLayout },
    { url: "/journey/:id", path: "project/projectDetail", layout: privateLayout },
    { url: "/journey/:id/:postId", path: "project/projectDetail", layout: privateLayout },
    { url: "/mybooking/:id", path: "Events/Booking", layout: privateLayout },
    { url: "/acceptInvitaion/:inviteCode", path: "project/acceptInvite", layout: privateLayout },
    { url: "/declineInvitaion/:inviteCode", path: "project/declineInvite", layout: privateLayout },
    { url: "/dashboard", path: "dashboard", layout: privateLayout },
    { url: "/events", path: "Events/event", layout: privateLayout },
    { url: "/addevent", path: "Events/AddEditEvent", layout: privateLayout },
    { url: "/editevent/:id", path: "Events/AddEditEvent", layout: privateLayout },
    { url: "/event/:title", path: "Events/EventReview", layout: privateLayout },
    { url: "/notifications", path: "ProjectNotifications", layout: privateLayout },
    { url: "/deleteaccount", path: "deleteaccount", layout: privateLayout },
    { url: "/terms", path: "Terms", layout: publicLayout },
    { url: "/about", path: "About", layout: publicLayout },
    { url: "/privacy", path: "Privacy", layout: publicLayout },
    { url: "/blockeduser", path: "BlockedUser", layout: publicLayout },
    { url: "/worldsavers", path: "WorldSavers", layout: privateLayout },
    { url: "/post/:id", path: "ViewPost", layout: privateLayout },
    { url: "/:id/:mode?", path: "member/memberDetail", layout: privateLayout },
  ]

  return (
    <TabsProvider>
      <Router>
        <Routes>
  
          {routes.map((itm) => {
            const Element = lazy(() => import(`../containers/${itm.path}`));
            return (
              <Route
                path={itm.url}
                element={
                  <AppRoute
                    Component={Element}
                    requireAuth={Auth}
                    layout={itm.layout}
                    store={store}
                    type="public"
                  />
                }
              />
            );
          })}
        </Routes>
      </Router>

      <YoutubePlayer />
    </TabsProvider>
  );
};

export default Routers;

import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Input, FormGroup, InputGroup } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { login } from '../../actions/user';
import logo from './../../assets/img/logo.png';
import { Formik } from 'formik';
import * as Yup from 'yup';

const LoginModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .email('Please enter a valid email address.')
      .required('Please enter your email address.'),
    password: Yup.string().required('Please enter your password.'),
  });

  // Initial form values
  const initialValues = {
    username: '',
    password: '',
  };

  const handleSubmit = (values) => {
    dispatch(login(values, navigate));
  };

  return (
    <div
      className="modal fade loginmodal"
      id="loginModal"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="text-right">
            <i
              className="fa fa-times signupClose"
              data-dismiss="modal"
              aria-label="Close"
            ></i>
          </div>
          <button
            type="button"
            className="close d-none"
            id="closeLogin"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="modal-login">
            <Link to="/dashboard">
              <img src={logo} className="modal-logo mt-4" alt="logo" />
            </Link>

            <div className="text-center color-white mb-4">
              <span>
                <b>Log In</b>
              </span>
            </div>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ handleChange, handleBlur, handleSubmit, values, errors }) => (
                <form onSubmit={handleSubmit} className="px-3 pb-3">
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Input
                        type="text"
                        name="username"
                        placeholder="Email Address"
                        autoComplete="off"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.username}
                        className={`form-control rdRev ${
                          errors.username ? 'is-invalid' : ''
                        }`}
                      />
                      {errors.username && (
                        <div className="error">{errors.username}</div>
                      )}
                    </InputGroup>
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Input
                        type="password"
                        name="password"
                        placeholder="Password"
                        autoComplete="new-password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        className={`form-control rdRev ${
                          errors.password ? 'is-invalid' : ''
                        }`}
                      />
                      {errors.password && (
                        <div className="error">{errors.password}</div>
                      )}
                    </InputGroup>
                    <div className="text-right mt-1">
                      <a
                        className="text-primary"
                        data-toggle="modal"
                        data-target="#forgotModal"
                      >
                        Forgot Password?
                      </a>
                    </div>
                  </FormGroup>

                  <div className="row">
                    <div className="col-12 text-center mt-5">
                      <Button
                        type="submit"
                        className="btn btn-primary rounded"
                        style={{ width: '100%' }}
                      >
                        Log In
                      </Button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
